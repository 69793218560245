import "@styles/style.scss";
import "./global.css";
import type { AppProps } from "next/app";
import { Toaster } from "react-hot-toast";
import Script from "next/script";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Script id="freshdesk">
        {`
				window.fwSettings={
				'widget_id':151000000771
				};
				!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() 
				`}
      </Script>
      <Script
        type="text/javascript"
        src="https://player.live-video.net/1.13.0/amazon-ivs-player.min.js"
        async
        defer
      ></Script>
      <Script
        type="text/javascript"
        src="https://widget.freshworks.com/widgets/151000000771.js"
        async
        defer
      ></Script>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerStyle={{
          zIndex: "2147483647",
          isolation: "isolate",
          transformStyle: "preserve-3d",
          transform: "translateZ(1000px)",
        }}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: "#E9FBED",
              border: "0.5px dashed #1FAD3E",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: "12px 8px",
              fontFamily: "Tiempos Headline",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "19px",
              textAlign: "center",
              color: "#34544F",
              borderRadius: "0px",
              zIndex: "2147483647",
            },
          },
          error: {
            style: {
              background: "#FCE8E8",
              border: "0.5px dashed #B81414",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: "12px 8px",
              fontFamily: "Tiempos Headline",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "19px",
              textAlign: "center",
              color: "#8A0F0F",
              borderRadius: "0px",
              zIndex: "2147483647",
            },
          },
        }}
      />
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
